import React, {useState} from 'react';
import Timepoint from './Timepoint';
import styled from "styled-components";
import data from './timedata.json';
import {Secondary} from "../Globals";

const Timeline = () => {
    const [showInfoIndex, setShowInfoIndex] = useState(null);

    const sortedData = [...data].sort((a, b) => {
        if (a.present && !b.present) {
            return 1;
        } else if (!a.present && b.present) {
            return -1;
        } else {
            return new Date(a.date_from) - new Date(b.date_from);
        }
    });

    return (
        <Container id="timeline">
            <h1>
                Time<Secondary>line</Secondary>
            </h1>
            <ContentWrapper>
                <Time>
                    <Line/>
                </Time>
                {sortedData.map((timepoint, index) => (
                    <Timepoint
                        key={index}
                        timepoint={timepoint}
                        index={index}
                        showInfo={showInfoIndex === index}
                        setShowInfo={() => setShowInfoIndex(showInfoIndex === index ? null : index)}
                    />
                ))}
            </ContentWrapper>
        </Container>
    );
};

export default Timeline;

const Container = styled.div`
  max-width: 1280px;
  width: 80%;
  position: relative;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1 {
    padding-top: 1rem;
  }
`;

const Line = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(---tertiary);
    z-index: -1;

    @media (max-width: 650px) {
        margin-left: 20px;
    }
`;

const ContentWrapper = styled.div`
  max-width: 1280px;
  width: 90%;
  position: relative;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;

const Time = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
