import React from 'react';
import styled from 'styled-components';
import {IoMdClose} from "react-icons/io";

const TimeInfo = ({position, skills, tasks, toggleInfo}) => {

    return (
        <InfoModal>
            <CloseButton onClick={toggleInfo}><IoMdClose/></CloseButton>
            <h3>{position}</h3>
            <Content>
                {tasks &&
                    <React.Fragment>
                        <h4>Tasks</h4>
                        <ul>
                            {tasks.map((task, index) => (
                                <li key={index}>{task}</li>
                            ))}
                        </ul>
                    </React.Fragment>
                }
                {skills &&
                    <React.Fragment>
                        <h4>Skills</h4>
                        {skills.map((skill, index) => (
                            <p key={index}>{skill}</p>
                        ))}
                    </React.Fragment>
                }
            </Content>
        </InfoModal>
    );
};

export default TimeInfo;

const InfoModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(---tertiary);
    color: var(---primary);
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    height: auto;
    max-height: 600px;
    z-index: 100;
    border: 5px solid var(---secondary);

    h3, h4, p {
        margin: 5px 0;
    }

    h3 {
        border-bottom: 2px solid var(---secondary);
    }

    h4 {
        border-bottom: 1px solid var(---secondary);
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    @media (max-width: 650px) {
        width: 300px;
    }
`;

const Content = styled.div`
    overflow-y: auto;
    max-height: 520px;
    padding: 10px;
    
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: var(---tertiary);
    }

    ::-webkit-scrollbar-thumb {
        background: var(---secondary);
    }
`;

const CloseButton = styled.button`
    color: var(---secondary);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
`;
